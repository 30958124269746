import BaseService from './BaseService';
import { StorageService } from './StorageService';
import { Constants } from './Constants';
import { CarModel } from '../models/CarModel';
import { UserModel } from '../models/UserModel';
import { PaginatedModel } from '../models/PaginatedModel';

export default class CarsService extends BaseService<CarModel> {

    constructor() {
        super("cars");
    }

    public async GetUserCars(callBack:(success: boolean, message: string, cars: Array<CarModel>) => void) {

        var userJson = StorageService.Get(Constants.UserKey);
        if (userJson) {
            var user = JSON.parse(userJson) as UserModel;
            if (user) {
                this.With("userId", user.id)
                    .Get(
                        0,
                        200,
                        async function (response: any) {
                            if (response) {
                                var paginatedCarsList = await response.json() as PaginatedModel<CarModel>;
                                console.log(paginatedCarsList);
                                callBack(true, "", paginatedCarsList.data);
                            } else {

                                // TODO - clear storage?
                                callBack(false, "Error: problem getting cars", []);
                            }
                        });
            } else {
                // TODO - redirect to sign in page?
            }
        }
    }

    public static New(): CarsService  {
        return new CarsService();
    }
}

