import React, { Component } from "react";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { CarParkReservationModel } from "../../models/CarParkReservationModel";
import ParkTakenComponent from "./ParkTakenComponent";

interface IProps {
    setShowPopup: (value: boolean) => void;
    showPopup: boolean;
    reservation: CarParkReservationModel;
}

interface IState {
}

class SomeonesInMyParkDialog extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
    }

    render() {
        return (
            <Modal
                title=""
                show={this.props.showPopup}
                onHide={this.handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Someones In My Park</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row style={{ height: '400px' }}>
                            <Col>
                                <ParkTakenComponent
                                    reservation={this.props.reservation}
                                    setDialogOpen={this.handleClose}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        Someones in my Park
                     </div>
                    <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        <Button onClick={this.handleClose}>Done</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SomeonesInMyParkDialog;