import React, { Component } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import { CreditCardModel } from "../../models/CreditCardModel";
import CreditCardService from "../../services/CreditCardService";

interface IProps {
    handleCreditCardSelected: (car: CreditCardModel) => void;
}

interface IState {

    errorOccured: boolean;
    errorMessage: string;

    loading: boolean;
    creditCards: Array<CreditCardModel>;
}

class CreditCardListComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,

            creditCards: new Array<CreditCardModel>(),
            loading: true
        };

        this.createCreditCardsList = this.createCreditCardsList.bind(this);
        this.setState = this.setState.bind(this);
        this.handleCreditCardsResponse = this.handleCreditCardsResponse.bind(this);
        this.handleCreditCardSelected = this.handleCreditCardSelected.bind(this);
    }

    async handleCreditCardsResponse(success: boolean, message: string, creditCards: Array<CreditCardModel>) {
        this.setState({ loading: false });

        if (success) {
            this.setState({ creditCards: creditCards });
        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: message });
        }
    }

    componentDidMount() {
        const service = CreditCardService.New();

        this.setState({ loading: true });

        service.GetUserCreditCards(this.handleCreditCardsResponse);
    }

    handleCreditCardSelected(e: any) {
        var creditCardId = e.target.id;
        var creditCard = this.state.creditCards.find(p => p.id === creditCardId);

        if (creditCard) {
            this.props.handleCreditCardSelected(creditCard)
        }
    }

    createCreditCardsList() {
        const creditCards =

            <Container>
                <Row>
                    <Col xs={8}>
                        <p>Credit Cards</p>
                    </Col>
                    <Col style={{ height: '50px' }}>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Card number</th>
                                    <th>Is Default</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.creditCards ? (
                                    this.state.creditCards.map((card: CreditCardModel, index) => {
                                        return (
                                            <tr
                                                onClick={this.handleCreditCardSelected}
                                                key={card.id}
                                                id={card.id}
                                                style={{ cursor: 'pointer' }}>
                                                <td id={card.id} >
                                                    {card.maskedNumber}
                                                </td>
                                                <td id={card.id} >
                                                    {card.isDefault}
                                                </td>
                                            </tr>
                                        )
                                    })) : (null)
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>

        return creditCards
    }

    render() {

        var creditCards = this.createCreditCardsList();

        return (
            <>
                {creditCards}
            </>);
    }
}

export default CreditCardListComponent;