import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { CurrentParkingRateModel } from "../../models/Parking/CurrentParkingRateModel";

interface IProps {
    parks: Array<CurrentParkingRateModel>;
    handleParkSelected: (value: string) => void;
    selectedSiteId: string;
}

interface IState {
    errorOccured: boolean;
    errorMessage: string;
    showYesNoPop: boolean;
}

class AvailableParksComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,
            showYesNoPop: false
        };

        this.createAvailableParksTable = this.createAvailableParksTable.bind(this);
        this.setState = this.setState.bind(this);
        this.parkSelectedHandler = this.parkSelectedHandler.bind(this);
    }

    parkSelectedHandler(e: any) {
        var selectedParkingRateId = e.target.id;
        if (selectedParkingRateId) {
           
            this.props.handleParkSelected(selectedParkingRateId);
        }
    }

    createAvailableParksTable() {

        const parks = <Table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Available</th>
                    <th>Charge</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {this.props.parks ? (
                    this.props.parks.length > 0 ? (
                        this.props.parks.map((parkRate: CurrentParkingRateModel, index) => {
                            return (
                                <tr
                                    onClick={this.parkSelectedHandler}
                                    key={parkRate.id}
                                    id={parkRate.id}
                                    style={{ cursor: 'pointer' }}>
                                    <td id={parkRate.id} >
                                        {parkRate.date}
                                    </td>
                                    <td id={parkRate.id} >
                                        {parkRate.rate.available ? (
                                            parkRate.rate.available)
                                            : ("Not set")}
                                    </td>
                                    <td id={parkRate.id} >
                                        {parkRate.rate.rate ? (
                                            <span> ${parkRate.rate.rate} </span>)
                                            : (<span>No charge</span>)}
                                    </td>
                                </tr>
                            )
                        }
                        )
                    ) : (
                            <tr>
                                <td colSpan={3}>
                                    {this.props.selectedSiteId.length > 0 ? (
                                        <>
                                            No parking currently available
                                        </>
                                    ):( 
                                    <>
                                        Select a site from the drop down
                                    </>)}
                                </td>

                            </tr>)
                ) : (null)}
            </tbody>
        </Table>

        return parks;
    }

    render() {
        var parks = this.createAvailableParksTable();
        return (
            <>
                {parks}
            </>);
    }
}

export default AvailableParksComponent;