import React, { Component } from "react";
import { Spinner,  Container, Row, Col } from "react-bootstrap";
import SiteDropDownComponent from '../SiteDropDownComponent';
import ParkingRatesService from "../../services/ParkingRatesService";
import AvailableParksComponent from './AvailableParksComponent';
import { CurrentParkingRateModel } from "../../models/Parking/CurrentParkingRateModel";
import GetDateFromString from '../../services/GetDateService';
import { uuid } from 'uuidv4';

interface IProps {
    handleRateSelected: (parkingRate: CurrentParkingRateModel) => void;
}

interface IState {
    errorOccured: boolean;
    errorMessage: string;
    selectedSiteId: string;
    parks: Array<CurrentParkingRateModel>;
    loading: boolean;
}

class ParkingRateComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,

            selectedSiteId: "",
            parks: new Array<CurrentParkingRateModel>(),
            loading: false
        };

        this.setState = this.setState.bind(this);
        this.setSelectedSiteId = this.setSelectedSiteId.bind(this);
        this.handleParkSelected = this.handleParkSelected.bind(this);
        this.handleGetCarParksResponse = this.handleGetCarParksResponse.bind(this);
    }

    async handleGetCarParksResponse(response: any) {
        this.setState({ loading: false });
        this.setState({ parks: new Array<CurrentParkingRateModel>() });

        if (response) {
            if (response.status === 200) {
                var responseData = await response.json() as Array<CurrentParkingRateModel>;

                if (responseData) {

                    let filteredParks = new Array<CurrentParkingRateModel>();

                    responseData.map(function (p, index) {

                        p.id = uuid();
                        var date = GetDateFromString(p.date);
                        if(date) {
                            p.parkingDate = date;
                            p.parkingDateDisplay = p.date;
                        } else {
                            alert("Error: Parking rate without date value.");
                        }

                        if (p.rate) {
                            if (p.rate.rate) {
                                if (p.rate.rate === 0) {
                                    filteredParks.push(p);
                                }
                            } else {
                                filteredParks.push(p);
                            }
                        } else {
                            alert("Error: No rate has been defined for this parking day.");
                        }
                    });

                    if (filteredParks) {

                        this.setState({ parks: filteredParks });
                    }
                } else if (response.status === 401) {

                }
            } else {

            }
        }
    }

    setSelectedSiteId(siteId: string) {
        this.setState({ selectedSiteId: siteId });

        const service = ParkingRatesService.New();

        this.setState({ loading: true });

        var url = "sites/" + siteId + "/parking-rates/upcoming";
        service.GetWithEndpoint(url, 0, 200, this.handleGetCarParksResponse)
    }

    handleParkSelected(parkingRateId: string) {
        var parkingRate = this.state.parks.find(p => p.id === parkingRateId);

        if (parkingRate) {
            this.props.handleRateSelected(parkingRate);
        }
    }

    render() {

        return (
            <>
                <Container>
                    <Row>
                        <Col xs={4}>
                            <p>Site</p>
                        </Col>
                        <Col style={{ height: '50px' }}>
                            <SiteDropDownComponent
                                selectedSiteId={this.state.selectedSiteId}
                                setSelectedSitePopup={this.setSelectedSiteId}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {this.state.loading ? (
                            <>
                                <Col>
                                </Col>
                                <Col xs={2}>
                                    < Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                        <span className="sr-only">...</span>
                                    </Spinner>

                                </Col>
                                <Col>
                                </Col>
                            </>) : (
                                <Col>
                                    <AvailableParksComponent
                                        parks={this.state.parks}
                                        handleParkSelected={this.handleParkSelected}
                                        selectedSiteId={this.state.selectedSiteId}
                                    />
                                </Col>
                            )}
                    </Row>
                </Container>

            </>);
    }
}

export default ParkingRateComponent;