import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import "../App.css";
import { PersonCheck, People, FilePpt } from 'react-bootstrap-icons';
import { RouteComponentProps, withRouter } from "react-router-dom";
import CheckSignInStatus from '../services/SigninService';

interface IProps extends RouteComponentProps {
}


export default class Home extends Component<IProps, {}, {}> {
    static displayName = Home.name;

    constructor(props: IProps) {
        super(props);

        this.handleDailyCheckInClick = this.handleDailyCheckInClick.bind(this);
        this.handleWellBringClick = this.handleWellBringClick.bind(this);
        this.handleMyTeamClick = this.handleMyTeamClick.bind(this);
        this.handleParkingClick = this.handleParkingClick.bind(this);
    }

    componentDidMount() {
        CheckSignInStatus();
    }

    handleDailyCheckInClick() {
        this.props.history.push("/dailycheckin");
    }

    handleWellBringClick() {
        this.props.history.push("mywellbeing");
    }

    handleMyTeamClick() {
        this.props.history.push("myteam");
    }

    handleParkingClick() {
        this.props.history.push("parking");
    }

    render() {
        return (
            <div style={{ paddingTop: "50px" }}>

                <Row className="mb-2">
                    <Col>
                        <Card
                            className="text-center"
                            onClick={this.handleDailyCheckInClick}
                            style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <h2 style={{ textAlign: "left" }}>
                                    <PersonCheck style={{ float: 'left', marginRight: "30px" }} size={40} /> Daily Checkin
                                    <Button style={{ float: 'right', width: '150px' }} variant="primary">View</Button>
                                </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col>
                        <Card
                            className="text-center"
                            onClick={this.handleMyTeamClick}
                            style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <h2 style={{textAlign:"left"}}>
                                    <People style={{ float: 'left', marginRight: "30px" }} size={40} /> My Team
                                    <Button style={{ float: 'right', width: '150px' }} variant="primary">View</Button>
                                </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Card
                            className="text-center"
                            onClick={this.handleParkingClick}
                            style={{ cursor: 'pointer' }}>
                            <Card.Body>

                                <h2 style={{ textAlign: "left" }}>
                                    <FilePpt style={{ float: 'left', marginRight: "30px" }} size={40} /> Parking
                                    <Button style={{ float: 'right', width: '150px' }} variant="primary">View</Button>
                                </h2>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
