import React, { Component } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { SiteModel } from "../models/SitesModel";
import { Constants } from "../services/Constants";
import { StorageService } from "../services/StorageService";
import SiteService from "../services/SiteService";
import { UserModel } from "../models/UserModel";

interface IProps {
    setSelectedSitePopup: (siteId: string) => void;
    selectedSiteId: string;
}

interface IState {
    errorOccured: boolean;
    errorMessage: string;
    sites: Array<SiteModel>;
}

class SiteDropDownComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            errorOccured: false,
            errorMessage: "",
            sites: new Array<SiteModel>()
        };

        this.setState = this.setState.bind(this);
        this.handleSiteSelectChange = this.handleSiteSelectChange.bind(this);
        this.handleSitesResponse = this.handleSitesResponse.bind(this);
    }

    handleSiteSelectChange(e: any) {
        let value = e.target.value;
        this.props.setSelectedSitePopup(value);
    }

    handleSitesResponse(success: boolean, message: string, sites: Array<SiteModel>) {
        if (success) {
            this.setState({ sites: sites });
        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: message });
        }
    }

    async componentDidMount() {
        var sitesData = StorageService.Get(Constants.SitesKey);
        if (sitesData) {
            var sites = JSON.parse(sitesData) as Array<SiteModel>;
            if(sites) {
            this.setState({ sites: sites });
            }
        } else {
            var userJson = StorageService.Get(Constants.UserKey);
            if (userJson) {
                var userModel = JSON.parse(userJson) as UserModel;
                if (userModel) {
                    var siteTypeIds = new Array<string>();
                    SiteService.New().GetUserSites(siteTypeIds, this.handleSitesResponse);
                } 
            }
        }
    }

    render() {
        return (
            <InputGroup className="mb-3">
                <FormControl
                    as="select"
                    onChange={this.handleSiteSelectChange}
                    value={this.props.selectedSiteId}
                >
                    <option id="select"> == select == </option>
                    {this.state.sites.map((el: SiteModel) =>
                        <option value={el.id} key={el.id}>{el.name}</option>)
                    }
                </FormControl>
                {this.state.errorOccured ? (
                    <div style={{ color: 'red' }}>
                        {this.state.errorMessage}
                    </div>
                ) : (null)}

            </InputGroup>);
    }
}

export default SiteDropDownComponent;