import { FormResponseModel } from '../models/FormResponse';
import BaseService from './BaseService';

export default class VLifeFormsService extends BaseService<any> {

    constructor() {
        super("forms");
    }

    public static New(): VLifeFormsService  {
        return new VLifeFormsService();
    }

    public SaveResponse(formId: string, responseModel: FormResponseModel, callBack: any) {

        var endpoint = "forms/" + formId + "/response" 
        this.CreateWithEndpoint(endpoint, responseModel, callBack);
    } 
}

