import { CurrentParkingRateModel } from '../models/Parking/CurrentParkingRateModel';
import BaseService from './BaseService';

export default class ParkingRatesService extends BaseService<CurrentParkingRateModel> {

    constructor() {
        super("parking-rates");
    }

    public static New(): ParkingRatesService  {
        return new ParkingRatesService();
    }
}

