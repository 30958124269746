import { UrlParameterService } from './urlparameter.service';
import TokenService from './TokenService';
import { Constants } from './Constants';
import TeamMembersService from './TeamMembersService';

const CheckSignInStatus = async () => {

    if (TokenService.IsAuthenticated()) {
        return;
    }

    var code = UrlParameterService.GetValue("code");
    var clientId = Constants.ClientId;

    if (code) {
        TokenService.GetNewToken(
            code,
            function (success: boolean) {
                if (success) {
                    TeamMembersService.New().GetCurrentUser(
                        function (success: boolean) {
                            if (success) {
                          
                            } else {
                                alert("Unable to get current user.")
                            }
                        });
                } else {
                    alert("Unable to get access token");
                }
            });

    } else {
        var url = Constants.VlifeDomainUrl;
        console.log("redirecting to " + url);
        window.location.assign(url + '/adfs/oauth2/authorize?response_type=code&client_id=' + clientId + '&redirect_uri=' + encodeURIComponent(window.location.href.split('?')[0]));
    }


}

export default CheckSignInStatus;