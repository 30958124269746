import React, { Component } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import CarsService from "../../services/CarsService";
import { CarModel } from "../../models/CarModel";
import CarListComponent from "./CarListComponent";
import AddCarDialog from './AddCarDialog';
import YesNoDialogComponent from "../YesNoDialogComponent";

interface IProps {
    setShowPopup: (value: boolean) => void;
    showPopup: boolean;
}

interface IState {
    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;
    carSaveSuccessful: boolean;
    showYesNoDialog: boolean;
    showAddCarDialog: boolean;
    selectedCar: CarModel;
    getCars: boolean;
}

class ManageCarsDialog extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            showAddCarDialog: false,
            loading: false,
            errorOccured: false,
            errorMessage: "",
            carSaveSuccessful: false,
            showYesNoDialog: false,
            selectedCar: new CarModel(),
            getCars: false
        }
        this.setState = this.setState.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setAddCarDialog = this.setAddCarDialog.bind(this);
        this.handleAddCarClick = this.handleAddCarClick.bind(this);
        this.handleCarSelected = this.handleCarSelected.bind(this);
        this.showYesNoPopupHandler = this.showYesNoPopupHandler.bind(this);
        this.setYesNoPopupAnswer = this.setYesNoPopupAnswer.bind(this);
        this.setGetCars = this.setGetCars.bind(this);
        this.handleDeletResponse = this.handleDeletResponse.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
    }

    setGetCars(value: boolean) {
        this.setState({ getCars: value });
    }

    handleCarSelected(car: CarModel) {
        this.setState({ selectedCar: car });
        this.setState({ showYesNoDialog: true });
    }

    setAddCarDialog(value: boolean) {
        this.setState({ showAddCarDialog: value });
        if (value == false) {
            this.setState({ getCars: true });
        }
    }

    handleAddCarClick() {
        this.setState({ showAddCarDialog: true });
    }

    showYesNoPopupHandler(value: boolean) {
        this.setState({ showYesNoDialog: value });
    }

    handleDeletResponse(response: any) {
        if (response) {
            if (response.status === 200 || response.status === 202) {
                this.setGetCars(true);
            }
        }
    }

    setYesNoPopupAnswer(value: string) {
        this.showYesNoPopupHandler(false);

        if (value === "yes") {
            var service = CarsService.New();

            service.Delete(this.state.selectedCar.id, this.handleDeletResponse);
        }
    }

    createModaloptionsPopup() {
        const modalPopup =
            <Modal
                title=""
                show={this.props.showPopup}
                onHide={this.handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Your Cars</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ height: '350px' }}>
                        <Row style={{ height: '50px' }}>
                            <Col>
                                <Button style={{ float: 'right' }} onClick={this.handleAddCarClick}>Add car</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CarListComponent
                                    handleCarSelected={this.handleCarSelected}
                                    getCars={this.state.getCars}
                                    setGetCars={this.setGetCars}
                                    displayDeleteIcon={true}
                                />
                            </Col>
                        </Row>
                        <Row style={{ height: '30px' }}>
                            <Col>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        Manage Cars
                     </div>
                     <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        <Button onClick={this.handleClose}>OK</Button>
                     </div>
                </Modal.Footer>

            </Modal>

        return modalPopup
    }

    render() {

        const popup = this.createModaloptionsPopup()
        return (
            <>
                <AddCarDialog
                    showPopup={this.state.showAddCarDialog}
                    setShowPopup={this.setAddCarDialog}
                />

                <YesNoDialogComponent
                    showPopup={this.state.showYesNoDialog}
                    setShowPopup={this.showYesNoPopupHandler}
                    handleAnswer={this.setYesNoPopupAnswer}
                    yesNoQuestion={"Are you sure you want to delete this car?"}
                />

                {popup}
            </>);
    }
}

export default ManageCarsDialog;