import TeamMemberModel from '../models/TeamMemberModel';
import { StorageService } from './StorageService';
import { Constants } from './Constants';
import BaseService from './BaseService';
import { UserModel } from '../models/UserModel';

export default class TeamMembersService extends BaseService<TeamMemberModel> {

    constructor() {
        super("users");
    }

    public async GetCurrentUser(callBack:(success: boolean, message: string, data: UserModel) => void) {

        this.GetDetail(
            "current",
            async function (response: any) {
                
                if (response) {
                    var user = await response.json();
                    console.log(user);
                    var userJson = JSON.stringify(user);
                    StorageService.Store(Constants.UserKey, userJson);
                    callBack(true, "", user);
                } else {

                    // TODO - clear storage?
                    callBack(false, "There was a problem getting current user", new UserModel());
                }
            });
    }

    public static New(): TeamMembersService  {
        return new TeamMembersService();
    }
}

