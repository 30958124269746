export class PostParkingReservationModel {
    sitename: string = "";
    siteId: string = "";
    displayDateBooked: string = "";
    dateBooked?: Date = undefined;
    carId: string = "";
    creditCardId: string | undefined = undefined;
    parkingBankId: string = "";
    rate: number = 0;
}
