import React, { Component } from "react";
import { Spinner, Modal, Button, Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import CarsService from "../../services/CarsService";
import { CarModel } from "../../models/CarModel";

interface IProps {
    setShowPopup: (value: boolean) => void;
    showPopup: boolean;
}

interface IState {
    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;

    makeAndModel: string;
    numberPlate: string;
    color: string;
    carAddedSuccess: boolean;
}

class AddCarDialog extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            errorOccured: false,
            errorMessage: "",
            makeAndModel: "",
            numberPlate: "",
            color: "",
            carAddedSuccess: false
        };

        this.setState = this.setState.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMakeAndModelChange = this.handleMakeAndModelChange.bind(this);
        this.handleNumberplateChange = this.handleNumberplateChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.handleOKClick = this.handleOKClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleAddCarResponse = this.handleAddCarResponse.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
    }

    handleMakeAndModelChange(e: any) {
        var text = e.target.value;
        this.setState({ makeAndModel: text });
    }

    handleNumberplateChange(e: any) {
        var text = e.target.value;
        this.setState({ numberPlate: text });
    }

    handleColorChange(e: any) {
        var text = e.target.value;
        this.setState({ color: text });
    }

    handleOKClick() {
        this.props.setShowPopup(false);
    }

    async handleAddCarResponse(response: any) {
        this.resetState();
        this.setState({ loading: false })
        if (response) {
            if (response.status === 200 || response.status === 201) {

                var responseData = await response.json() as CarModel;
                if (responseData) {
                    this.setState({ carAddedSuccess: true });
                }
            } else if (response.status === 400) {
                // Display validation errors
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Sorry - we are unable to add your car at this time" });
                var validationData = await response.json();
                if (validationData.code) {
                    if (validationData.code === "ValidationException") {
                        if (validationData.errors) {
                            if (validationData.errors.length > 0) {
                                var error = validationData.errors.find((e: any) => e.key === "NumberPlate");
                                if (error) {
                                    this.setState({ errorMessage: error.error });
                                }
                            }
                        }
                    }
                }

            } else {
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Error: there was a problem adding your car." });
            }

        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: there was a problem adding your car." });
        }
    }

    handleSaveClick() {

        this.resetState();
        this.setState({ loading: true });

        var carModel = new CarModel();
        carModel.name = this.state.makeAndModel;
        carModel.numberPlate = this.state.numberPlate;
        carModel.colour = this.state.color;

        var service = CarsService.New();

        service.Create(carModel, this.handleAddCarResponse);
    }

    resetState() {
        this.setState({ errorOccured: false });
        this.setState({ errorMessage: "" });
        this.setState({ carAddedSuccess: false });
        this.setState({ loading: false });

    }

    createModaloptionsPopup() {
        const modalPopup =
            <Modal
                title=""
                show={this.props.showPopup}
                onHide={this.handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Add Car</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ height: '350px' }}>
                        {this.state.loading ? (
                            <Row>
                                <Col >
                                    <div style={{ textAlign: 'center' }}>
                                        <Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                            <span className="sr-only">...</span>
                                        </Spinner>
                                    </div>
                                </Col>
                            </Row>

                        ) : (this.state.carAddedSuccess ? (
                            <>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col xs={6} style={{ textAlign: "center", color: 'green' }}>
                                        <h4>Your new car was added successfully</h4>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: "center", color: 'green' }}>

                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                                <>
                                    <Row style={{ height: '40px' }}>
                                        <Col>
                                            <InputGroup>
                                                <FormControl
                                                    type='text'
                                                    placeholder="Make and model"
                                                    aria-label="Make"
                                                    aria-describedby="basic-addon1"
                                                    onChange={this.handleMakeAndModelChange}
                                                    value={this.state.makeAndModel}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ height: '40px' }}>
                                        <Col>
                                            <InputGroup>
                                                <FormControl
                                                    type='text'
                                                    placeholder="Number plate"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    onChange={this.handleNumberplateChange}
                                                    value={this.state.numberPlate}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ height: '40px' }}>
                                        <Col>
                                            <InputGroup>
                                                <FormControl
                                                    type='text'
                                                    placeholder="Color"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    onChange={this.handleColorChange}
                                                    value={this.state.color}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ height: '40px' }}>
                                        <Col></Col>
                                        <Col xs={4}>
                                            <Button style={{ width: '120px' }} onClick={this.handleSaveClick}>Save</Button>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </>
                            ))}

                        <Row style={{ height: '40px' }}>
                            <Col></Col>
                            <Col xs={8}>
                                <p style={{ textAlign: "center", color: 'red' }}>
                                    {this.state.errorMessage}
                                </p>
                            </Col>
                            <Col></Col>

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        Add Car
                    </div>
                    {this.state.carAddedSuccess ? (
                        <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                            <Button style={{ width: '120px' }} onClick={this.handleOKClick}>
                                OK
                            </Button>
                        </div>
                    ) : (
                            <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                                <Button onClick={this.handleClose}>Cancel</Button>
                            </div>
                        )}
                </Modal.Footer>
            </Modal>
        return modalPopup
    }

    render() {

        const popup = this.createModaloptionsPopup()
        return (
            <>
                {popup}
            </>);
    }
}

export default AddCarDialog;