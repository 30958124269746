import React, { Component } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { CreditCardModel } from "../../models/CreditCardModel";
import { CurrentParkingRateModel } from "../../models/Parking/CurrentParkingRateModel";
import ParkingRateComponent from './ParkingRateComponent'
import { PostParkingReservationModel } from "../../models/Parking/PostParkingReservationModel";
import CreditCardListComponent from "./CreditCardListComponent";
import PreviewBookingComponent from "./PreviewBookingComponent";
import CarListComponent from './CarListComponent'
import { CarModel } from "../../models/CarModel";
import { SiteModel } from "../../models/SitesModel";
import ManageCarsDialog from './ManageCarsDialog';

interface IProps {
    showPopup: boolean;
    setShowPopup: (value: boolean) => void;
}

interface IState {
    currentPageNumber: number;

    errorOccured: boolean;
    errorMessage: string;
    submitSuccess: boolean;

    cancelLoading: boolean;
    cancelSuccess: boolean;

    showYesNoPop: boolean;

    showManagedCars: boolean;

    selectedSiteId: string;

    loading: boolean;
    parks: Array<CurrentParkingRateModel>;

    parkingReservation: PostParkingReservationModel;

    parkingRateDisplay: string;
    carListDisplay: string;
    creditCardListDisplay: string;
    previewBookingDisplay: string;

    selectedSite: SiteModel;

    selectedCar: CarModel;
    getCars: boolean;

    bookingSavedSuccesfully: boolean;
}

class BookParkingComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            currentPageNumber: 1,
            errorMessage: "",
            errorOccured: false,
            submitSuccess: false,
            cancelLoading: false,
            cancelSuccess: false,

            showManagedCars: false,

            showYesNoPop: false,
            selectedSiteId: "",
            parks: new Array<CurrentParkingRateModel>(),
            loading: true,

            parkingReservation: new PostParkingReservationModel(),

            parkingRateDisplay: 'block',
            carListDisplay: 'none',
            creditCardListDisplay: 'none',
            previewBookingDisplay: 'none',
            selectedSite: new SiteModel(),
            selectedCar: new CarModel(),
            getCars: false,
            bookingSavedSuccesfully: false

        };

        this.createModaloptionsPopup = this.createModaloptionsPopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.createModaloptionsPopup = this.createModaloptionsPopup.bind(this);
        this.handleParkSelected = this.handleParkSelected.bind(this);
        this.handleCarSelected = this.handleCarSelected.bind(this);
        this.handleCreditCardSelected = this.handleCreditCardSelected.bind(this);
        this.setShowManagedCars = this.setShowManagedCars.bind(this);
        this.handleManageCarsClick = this.handleManageCarsClick.bind(this);
        this.setGetCars = this.setGetCars.bind(this);
        this.handleSetBookingStatus = this.handleSetBookingStatus.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
        this.setState({ parkingRateDisplay: 'block' });
        this.setState({ creditCardListDisplay: 'none' });
        this.setState({ previewBookingDisplay: 'none' });
        this.setState({ carListDisplay: 'none' });
    }

    handleParkSelected(parkingRate: CurrentParkingRateModel) {
        if (parkingRate) {
            var reservationCopy = Object.assign({}, this.state.parkingReservation) as PostParkingReservationModel;

            reservationCopy.siteId = parkingRate.rate.site.id;
            reservationCopy.sitename = parkingRate.rate.site.name;
            reservationCopy.rate = parkingRate.rate.rate;

            reservationCopy.displayDateBooked = parkingRate.date;
            reservationCopy.dateBooked = parkingRate.parkingDate;

            this.setState({ parkingReservation: reservationCopy });

            this.setState({ parkingRateDisplay: 'none' });
            this.setState({ creditCardListDisplay: 'none' });
            this.setState({ previewBookingDisplay: 'none' });
            this.setState({ carListDisplay: 'block' });
        }
    }

    handleCarSelected(car: CarModel) {
        if (car) {
            var reservationCopy = Object.assign({}, this.state.parkingReservation) as PostParkingReservationModel;

            reservationCopy.carId = car.id;

            this.setState({ parkingReservation: reservationCopy });

            if (reservationCopy.rate === 0) {
                this.setState({ parkingRateDisplay: 'none' });
                this.setState({ carListDisplay: 'none' });
                this.setState({ creditCardListDisplay: 'none' });
                this.setState({ previewBookingDisplay: 'block' });
            } else {
                this.setState({ parkingRateDisplay: 'none' });
                this.setState({ carListDisplay: 'none' });
                this.setState({ creditCardListDisplay: 'block' });
                this.setState({ previewBookingDisplay: 'none' });
            }
        }
    }

    handleCreditCardSelected(card: CreditCardModel) {
        if (card) {
            var reservationCopy = Object.assign({}, this.state.parkingReservation) as PostParkingReservationModel;

            reservationCopy.creditCardId = card.id;

            this.setState({ parkingReservation: reservationCopy });

            this.setState({ parkingRateDisplay: 'none' });
            this.setState({ carListDisplay: 'none' });
            this.setState({ creditCardListDisplay: 'none' });
            this.setState({ previewBookingDisplay: 'block' });
        }
    }

    handleManageCarsClick() {
        this.setState({ showManagedCars: true });
    }

    setShowManagedCars(value: boolean) {
        this.setState({ showManagedCars: value });
        this.setGetCars(true);
    }

    setGetCars(value: boolean) {
        this.setState({ getCars: value });
    }

    handleSetBookingStatus(value:boolean) {
        this.setState({bookingSavedSuccesfully: value});
    }

    createModaloptionsPopup() {
        const modalPopup =
            <div style={{ maxWidth: '100%' }}>
                <Modal
                    title=""

                    show={this.props.showPopup}
                    onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Book Parking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ height: '350px' }}>
                            <div style={{ display: this.state.parkingRateDisplay }}>
                                <ParkingRateComponent
                                    handleRateSelected={this.handleParkSelected}
                                />
                            </div>
                            <div style={{ display: this.state.carListDisplay }}>
                                <Row>
                                    <Col>
                                        <Button style={{ float: 'right' }} onClick={this.handleManageCarsClick}>Manage Cars</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: '15px' }}>
                                            <CarListComponent
                                                handleCarSelected={this.handleCarSelected}
                                                getCars={this.state.getCars}
                                                setGetCars={this.setGetCars}
                                                displayDeleteIcon={false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ display: this.state.creditCardListDisplay }}>
                                <CreditCardListComponent
                                    handleCreditCardSelected={this.handleCreditCardSelected}
                                />
                            </div>
                            <div style={{ display: this.state.previewBookingDisplay }}>
                                <PreviewBookingComponent
                                    reservationRequest={this.state.parkingReservation}
                                    handleClose={this.handleClose}
                                    setSaveBookingStatus={this.handleSetBookingStatus}
                                />
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                            Book Parking
                        </div>
                        {!this.state.bookingSavedSuccesfully ? (
                            <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                                <Button onClick={this.handleClose}>Cancel</Button>
                            </div>
                        ) : (null)}
                    </Modal.Footer>
                </Modal>
            </div>
        return modalPopup
    }

    render() {

        var popUp = this.createModaloptionsPopup();

        return (
            <>
                <ManageCarsDialog
                    showPopup={this.state.showManagedCars}
                    setShowPopup={this.setShowManagedCars} />
                {popUp}
            </>);
    }
}

export default BookParkingComponent;