import React, { Component } from 'react';
import { Button, ButtonGroup, Card, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { FormQuestionResponseModel } from '../models/FormQuestionResponse';
import { FormResponseModel } from '../models/FormResponse';
import { FormSubmitResponseModel } from '../models/FormSubmitResponseModel';
import { SiteModel } from '../models/SitesModel';
import { Constants } from '../services/Constants';
import SiteService from '../services/SiteService';
import { StorageService } from '../services/StorageService';
import VLifeFormsService from '../services/VlifeFormsService';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PaginatedModel } from '../models/PaginatedModel';

interface IProps extends RouteComponentProps {
}

interface IState {
    survey: any;
    currentQuestion: any;
    currentQuestionIndex: number;
    total: number;
    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;
    responseModel: FormResponseModel;
    submitResponse: FormSubmitResponseModel;
    submitSuccess: boolean;
    textAreaText: string;
}

class DailyCheckInComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            survey: {},
            currentQuestion: {},
            currentQuestionIndex: 0,
            total: 0,
            loading: false,
            responseModel: new FormResponseModel(),
            errorMessage: "",
            errorOccured: false,
            submitResponse: new FormSubmitResponseModel(),
            submitSuccess: false,
            textAreaText: ""
        };

        this.handleResponseData = this.handleResponseData.bind(this);
        this.handleButtonClicked = this.handleButtonClicked.bind(this);
        this.setNextQuestion = this.setNextQuestion.bind(this);
        this.setCurrentQuestion = this.setCurrentQuestion.bind(this);
        this.handleSiteSelectChange = this.handleSiteSelectChange.bind(this);
        this.addAnseredQuestionToResponse = this.addAnseredQuestionToResponse.bind(this);
        this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
        this.handleSubmitCallBack = this.handleSubmitCallBack.bind(this);
        this.clearErrorMessage = this.clearErrorMessage.bind(this);
        this.handleActionButtonClicked = this.handleActionButtonClicked.bind(this);
        this.handleRadioButtonClicked = this.handleRadioButtonClicked.bind(this);
        this.handleTextTextAreaChange = this.handleTextTextAreaChange.bind(this);
        this.handleLongTextButtonClick = this.handleLongTextButtonClick.bind(this);
    }

    handleButtonClicked(e: any) {

        var selectedButtonValue = e.target.value;
        console.log(selectedButtonValue);
        this.setNextQuestion(selectedButtonValue);
    }

    handleActionButtonClicked(e: any) {

        var selectedButtonValue = e.target.value;
        console.log(selectedButtonValue);

        if (selectedButtonValue === "close") {
            this.props.history.push("/home");
        }
    }

    setNextQuestion(selectedButtonValue: string) {
        this.addAnseredQuestionToResponse(this.state.currentQuestion.id, selectedButtonValue);

        var logicEnties = [];

        for (var i = 0; i < this.state.survey.definition.logic.length; i++) {
            if (this.state.survey.definition.logic[i].source === this.state.currentQuestion.id) {
                logicEnties.push(this.state.survey.definition.logic[i]);
            }
        }

        if (logicEnties.length > 0) {

            for (var i = 0; i < logicEnties.length; i++) {
                var logicEntity = logicEnties[i];

                if (logicEntity.condition) {
                    if (logicEntity.condition.op) {
                        if (logicEntity.condition.op === 'equal') {
                            if (logicEntity.condition.value) {
                                if (selectedButtonValue === logicEntity.condition.value) {
                                    this.setCurrentQuestion(logicEntity.destination);
                                }
                            }
                        } else if (logicEntity.condition.op === 'always') {
                            this.setCurrentQuestion(logicEntity.destination);
                        } else {
                            // TODO handle other op values
                        }
                    }
                }
            }
        } else {
            this.setNextQuestionByincrement();
        }
    }

    setCurrentQuestion(destinationQuestionId: string) {
        var destinationQuestion = this.state.survey.definition.fields.find((f: { id: string; }) => f.id === destinationQuestionId);

        var index = 0;
        if (destinationQuestion) {
            for (var i = 0; i < this.state.survey.definition.fields.length; i++) {

                if (this.state.survey.definition.fields[i].id === destinationQuestion) {
                    index = i;
                    break;
                }
            }
            this.setState({ currentQuestionIndex: index });
            this.setState({ currentQuestion: destinationQuestion });
        }
    }

    componentDidMount() {
        var user = StorageService.Get(Constants.UserKey);
        if (user) {
            var userModel = JSON.parse(user);
            if (userModel) {

                this.setState({ loading: true });
                VLifeFormsService.New()
                    .With("formType", "wellbeing")
                    .Get(0, 200, this.handleResponseData);
            }
        }
    }

    getSiteTypesFromQuestionnaire() {
        var field = this.state.survey.definition.fields.find((l: { type: string; }) => l.type === "site");
        let siteTypeIds: string[] = [];
        if (field) {
            if (field.settings) {
                siteTypeIds = field.settings.siteTypes;
            }
        }

        return siteTypeIds;
    }

    setNextQuestionByincrement() {
        var nextIndex1 = this.state.currentQuestionIndex + 1;
        if (nextIndex1) {
            var nextQuestion1 = this.state.survey.definition.fields[nextIndex1];
            if (nextQuestion1) {
                this.setState({ currentQuestionIndex: nextIndex1 });
                this.setState({ currentQuestion: nextQuestion1 });
            }
        }
    }

    handleSitesResponse(success: boolean, message: string, sites: Array<SiteModel>) {
        if (!success) {
            alert(message);
        }
    }

    async handleResponseData(result: any) {
        this.setState({ loading: false });

        try {
            if (result) {
                if (result.status === 200) {
                    var responseData = await result.json() as PaginatedModel<any>;
                    if (responseData) {
                        if (responseData.data) {

                            if (responseData.data.length > 0) {
                                console.log(responseData);
                                this.setState({ survey: responseData.data[0] })

                                if (responseData.data[0].definition) {
                                    if (responseData.data[0].definition.fields) {
                                        if (responseData.data[0].definition.fields.length > 0) {
                                            this.setState({ currentQuestionIndex: 0 });
                                            this.setState({ currentQuestion: responseData.data[0].definition.fields[0] });

                                            // Get list of sites for user
                                            var siteTypes = this.getSiteTypesFromQuestionnaire();
                                            if (siteTypes.length > 0) {
                                                SiteService.New().GetUserSites(siteTypes, this.handleSitesResponse);
                                            } else {
                                                // TODO - if no site types in questionnaire do we still need to fetch 
                                                // sites and how do we filter them?
                                            }
                                        }
                                    }
                                }
                            }
                            else {
                                this.setState({ errorOccured: true });
                                this.setState({ errorMessage: "Error: Missing questionnaire definition." });
                            }

                        } else {
                            this.setState({ errorOccured: true });
                            this.setState({ errorMessage: "Error: Unabe to retrieve questionnaire." });
                        }
                    }
                } else if (result.status === 401) {
                    StorageService.Clear(Constants.UserKey);
                    StorageService.Clear(Constants.SitesKey);
                    StorageService.Clear(Constants.TokenKey);
                    StorageService.Clear(Constants.RefreshTokenKey);

                    window.location.href = "/signin";
                } else {
                    this.setState({ errorOccured: true });
                    this.setState({ errorMessage: "Error: Unabe to retrieve questionnaire." });
                }
            } else {
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Error: Unabe to retrieve questionnaire." });
            }
        } catch (Error) {
            this.setState({ errorOccured: true });
            this.setErrorMessage("Error: There a problem retreiving the survey.")
        }
    }

    addAnseredQuestionToResponse(questionId: string, answervalue: string) {
        var answerModel = new FormQuestionResponseModel();
        answerModel.id = questionId;
        answerModel.value = answervalue;

        var copiedResponse = Object.assign({}, this.state.responseModel);
        copiedResponse.answers.push(answerModel);

        this.setState({ responseModel: copiedResponse })
    }

    handleRadioButtonClicked(e: any) {
        var buttonValue = e.target.id;
        this.setNextQuestion(buttonValue);
    }

    getCurrentRatingOptions() {

        var radioButtonGrooup = {};

        if (this.state.currentQuestion) {
            if (this.state.currentQuestion.type === "rating") {
                if (this.state.currentQuestion.settings) {
                    if (this.state.currentQuestion.settings) {

                        if (this.state.currentQuestion.settings.shape) {
                            if (this.state.currentQuestion.settings.shape === "number") {

                                let endText = this.state.currentQuestion.settings.end;
                                let startText = this.state.currentQuestion.settings.start;

                                let steps = this.state.currentQuestion.settings.steps as number;
                                if (steps) {

                                    let listOfButtons = [];
                                    for (var i = 0; i < steps; i++) {
                                        listOfButtons.push(
                                            <Button
                                                onClick={this.handleRadioButtonClicked}
                                                id={i.toString()}
                                                key={i}>{i}
                                            </Button>);
                                    }

                                    radioButtonGrooup =
                                        <div>
                                            <div style={{ float: 'left' }}>
                                                {startText}
                                            </div>
                                            <div style={{ float: 'right' }}>
                                                {endText}
                                            </div>
                                            <ButtonGroup style={{ width: '100%' }}>
                                                {listOfButtons}
                                            </ButtonGroup >
                                        </div>
                                }
                            }
                        }
                    }
                }
            }
        }

        return radioButtonGrooup;
    }

    getCurrentQuestionOptions() {

        var listOfButtons = [];

        if (this.state.currentQuestion) {
            if (this.state.currentQuestion.options) {
                let buttonOptions = this.state.currentQuestion.options;
                for (var i = 0; i < buttonOptions.length; i++) {
                    listOfButtons.push(
                        <Button
                            key={buttonOptions[i].value}
                            value={buttonOptions[i].value}
                            onClick={this.handleButtonClicked}
                            style={{ marginRight: '10px' }}>
                            {buttonOptions[i].displayText}
                        </Button>)
                }
            }
        }

        return listOfButtons;
    }

    getStatmentOptions() {
        if (this.state.currentQuestion) {
            if (this.state.currentQuestion.type === "statement") {

                const statementView =
                    <>
                        <p>{this.state.currentQuestion.content}</p>
                        <Button
                            key={this.state.currentQuestion.id}
                            onClick={this.handleSubmitClicked}
                            style={{ marginRight: '10px' }}>
                            Submit
                        </Button>
                    </>

                return statementView;
            }
        }
    }

    getSubmitResponseDetails() {
        if (this.state.submitSuccess) {
            if (this.state.submitResponse) {
                let listOfActions = [];

                let actions = this.state.submitResponse.actions;
                for (var i = 0; i < actions.length; i++) {
                    listOfActions.push(
                        <Button
                            key={actions[i].id}
                            value={actions[i].value}
                            onClick={this.handleActionButtonClicked}
                            style={{ marginRight: '10px' }}
                        >
                            {actions[i].displayText}
                        </Button>)
                }

                return listOfActions;
            }
        }
    }

    handleSubmitClicked() {

        if (this.state.survey) {
            if (this.state.survey.id) {
                var surveyId = this.state.survey.id;
                this.setState({ loading: true });

                VLifeFormsService.New()
                    .SaveResponse(
                        surveyId,
                        this.state.responseModel,
                        this.handleSubmitCallBack);
            }
        }
    }

    async handleSubmitCallBack(response: any) {
        this.clearErrorMessage();

        if (response) {
            if (response.status === 200) {
                var submitResponse = await response.json() as FormSubmitResponseModel;

                if (submitResponse) {
                    this.setState({ submitResponse: submitResponse });
                    this.setState({ loading: false });
                    this.setState({ submitSuccess: true });
                } else {
                    this.setErrorMessage("There a problem reading submit response.")
                }

            } else {
                this.setErrorMessage("Theres an error submitting the form.")
            }
        } else {
            this.setErrorMessage("The response is undefined or null.")
        }
    }

    getSiteQuestionOptions() {

        if (this.state.currentQuestion) {
            if (this.state.currentQuestion.type === "site") {
                var sitesJson = StorageService.Get(Constants.SitesKey);
                if (sitesJson) {
                    var siteModels = JSON.parse(sitesJson);
                    if (siteModels) {
                        let siteTypes = this.state.currentQuestion.settings.siteTypes;
                        //TODO filter sites by types
                        const siteDropDown =
                            <InputGroup className="mb-3">
                                <FormControl
                                    as="select"
                                    onChange={this.handleSiteSelectChange}
                                >
                                    <option id="select"> == select == </option>
                                    {siteModels.map((el: SiteModel) =>
                                        <option value={el.id} key={el.id}>{el.name}</option>)
                                    }
                                </FormControl>
                            </InputGroup>

                        return siteDropDown;
                    }
                }
            }
        }

        return null;
    }

    handleTextTextAreaChange(e: any) {
        var text = e.target.value;

        this.setState({ textAreaText: text });
    }

    handleLongTextButtonClick() {
        var userComment = this.state.textAreaText;

        this.setNextQuestion(userComment);
        this.setState({ textAreaText: "" });
    }

    getLongTextOptions() {

        if (this.state.currentQuestion) {
            if (this.state.currentQuestion.type === "longText") {

                const longTextDisplay =
                    <div>
                        <InputGroup>
                            <FormControl
                                as="textarea"
                                aria-label=""
                                value={this.state.textAreaText}
                                onChange={this.handleTextTextAreaChange}
                            />
                        </InputGroup>
                        <Button style={{ float: 'right' }} onClick={this.handleLongTextButtonClick}>Next</Button>
                    </div>

                return longTextDisplay;
            }
        }

        return null;
    }

    handleSiteSelectChange(e: any) {

        var siteId = e.target.value;
        console.log(siteId);

        this.setNextQuestion(siteId);
    }

    setErrorMessage(errorMessage: string) {
        this.setState({ errorOccured: true });
        this.setState({ errorMessage: errorMessage });
    }

    clearErrorMessage() {
        this.setState({ errorOccured: false });
        this.setState({ errorMessage: "" });
    }

    render() {

        const rows = this.getCurrentQuestionOptions();
        const sitesDropDown = this.getSiteQuestionOptions();
        const statement = this.getStatmentOptions();
        const submitResponseActions = this.getSubmitResponseDetails();
        const ratingsButtonGroup = this.getCurrentRatingOptions();
        const longTextGroup = this.getLongTextOptions();

        return (
            <>
                {this.state.errorOccured ? (
                    <div style={{ color: 'red' }}>
                        {this.state.errorMessage}
                    </div>) : (
                        this.state.loading ? (
                            <Spinner style={{ marginTop: '10px' }} animation="border" role="status" size="sm">
                                <span className="sr-only">...</span>
                            </Spinner>
                        ) : (this.state.submitSuccess ? (
                            <Card style={{ width: '18rem', margin: "auto", marginTop: "30px" }}>
                                <Card.Body>
                                    <Card.Title>{this.state.submitResponse.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{this.state.submitResponse.description}</Card.Subtitle>
                                    <Card.Text style={{ marginTop: "20px" }}>
                                        {submitResponseActions}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        ) : (
                                (this.state.currentQuestion.type === "buttonOptions" ? (

                                    <Card style={{ width: '18rem', margin: "auto", marginTop: "30px" }}>
                                        <Card.Body>
                                            <Card.Title>{this.state.currentQuestion.title}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                            <Card.Text style={{ marginTop: "20px" }}>
                                                {rows.map(function (element) {
                                                    return element;
                                                })}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                ) :
                                    (
                                        this.state.currentQuestion.type === "site" ? (
                                            <Card style={{ width: '18rem', margin: "auto", marginTop: "30px" }}>
                                                <Card.Body>
                                                    <Card.Title>{this.state.currentQuestion.title}</Card.Title>
                                                    <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                                    <Card.Text style={{ marginTop: "20px" }}>
                                                        {sitesDropDown}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        ) : (this.state.currentQuestion.type === "statement" ? (

                                            <Card style={{ width: '18rem', margin: "auto", marginTop: "30px" }}>
                                                <Card.Body>
                                                    <Card.Title>{this.state.currentQuestion.title}</Card.Title>
                                                    <Card.Subtitle className="mb-2 text-muted">{this.state.errorMessage}</Card.Subtitle>
                                                    <Card.Text style={{ marginTop: "20px" }}>
                                                        {statement}
                                                    </Card.Text>

                                                </Card.Body>
                                            </Card>
                                        ) : (this.state.currentQuestion.type === "rating" ? (
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>{this.state.currentQuestion.title}</Card.Title>
                                                    <Card.Subtitle className="mb-2 text-muted">{this.state.errorMessage}</Card.Subtitle>
                                                    <Card.Text style={{ marginTop: "20px" }}>
                                                        {ratingsButtonGroup}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>) : (
                                                this.state.currentQuestion.type === "longText" ? (
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>{this.state.currentQuestion.title}</Card.Title>
                                                            <Card.Subtitle className="mb-2 text-muted">{this.state.errorMessage}</Card.Subtitle>
                                                            <Card.Text style={{ marginTop: "20px" }}>
                                                                {longTextGroup}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                ) : (null))
                                            )
                                            ))))))}
            </>
        );
    }
}

export default withRouter(DailyCheckInComponent);