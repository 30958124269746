const GetDateFromString = (dateAsString: string) => {
    if(dateAsString){
        if(dateAsString.length > 0){
            let dayindex = dateAsString.indexOf("-");
            let day = dateAsString.substr(0, dayindex);

            let dayInt = parseInt(day);

            let restOfDate = dateAsString.substr(dayindex + 1);

            let monthIndex = restOfDate.indexOf("-");
            let month = restOfDate.substr(0, monthIndex);    
            let monthInt = parseInt(month);

            restOfDate = restOfDate.substr(monthIndex + 1);
            let year = restOfDate;

            let yearInt = parseInt(year);

            if(dayInt && monthInt && yearInt){
                var date = new Date(yearInt, monthInt - 1, dayInt);
                return date;
            }
        }
    }

    return null;
}

export default GetDateFromString;