import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

import Home from './pages/Home';
import Parking from './pages/Parking';
import { DailyCheckin } from './pages/DailyCheckin';
import { MyTeam } from './pages/MyTeam';
import MyWellBeing  from './pages/MyWellBeing';
import { BookADesk } from './pages/BookADesk';
import { Login } from "./pages/Login";

import './custom.css'

interface IProps {
}

interface IState {}

export default class App  extends Component<IProps, IState, {}> {
    static displayName = App.name;

    render() {
        return (
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/signin' component={Login} />
                    <Route exact path='/home' component={Home} />
                    <Route exact path='/parking' component={Parking} /> 
                    <Route exact path='/dailycheckin' component={DailyCheckin} />
                    <Route exact path='/myteam' component={MyTeam} />
                    <Route path='/mywellbeing' component={MyWellBeing} />
                    <Route exact path='/bookadesk' component={BookADesk} />
                    <Route exact path='/signin' component={DailyCheckin} />
                </Layout>);
    }
}
