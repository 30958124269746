import { ServiceCallback } from "../models/Models"
import { StorageService } from "./StorageService";
import { Constants } from "./Constants";

export default class TokenService {

    private static clientId: string = Constants.ClientId;

    public static IsAuthenticated() {
        try {

            if (StorageService.Get(Constants.TokenKey)) {
                var userJson = StorageService.Get(Constants.UserKey);
                if (userJson) {

                    var user = JSON.parse(userJson)
                    if (user) {
                        if (user.id && user.id.length > 0) {
                            if (StorageService.Get(Constants.TokenKey)) {
                                return true;
                            }
                        }
                    }
                }

                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    public static Get() {
        return StorageService.Get(Constants.TokenKey);
    }

    public static async RefreshToken(callback: any) {

        let url = Constants.VlifeDomainUrl + '/adfs/oauth2/token';

        var data = {
            client_id: this.clientId,
            grant_type: 'refresh_token',
            refresh_token: StorageService.Get(Constants.RefreshTokenKey)
        };

        var json = JSON.stringify(data);

        var response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: json
            });

        var responseData = await response.json();

        if (responseData && responseData.access_token) {
            StorageService.Store(Constants.TokenKey, responseData.access_token);
            if (responseData.refresh_token) {
                StorageService.Store(Constants.RefreshTokenKey, responseData.refresh_token);
            }
            if (callback) {
                callback(true);
            }
        } else {
            StorageService.Clear(Constants.TokenKey);
            if (callback) {
                callback(false);
            }
        }
    }

    public static async GetNewToken(code: string, callback: any) {
        let url = Constants.VlifeDomainUrl + '/adfs/oauth2/token';

        var formBody = [];

        formBody.push('client_id' + "=" + TokenService.clientId);
        formBody.push('grant_type' + "=" + 'authorization_code');
        formBody.push('code' + "=" + code);
        formBody.push('redirect_uri' + "=" + encodeURIComponent(window.location.href.split('?')[0]));

        try {
            var response = await fetch(url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: formBody.join("&")
                });

            var data = await response.json();
            console.log(data);

            if (data && data.access_token) {
                StorageService.Store(Constants.TokenKey, data.access_token);
                if (data.refresh_token) {
                    StorageService.Store(Constants.RefreshTokenKey, data.refresh_token);
                }
                if (callback) {
                    callback(true);
                }
            } else {
                StorageService.Clear(Constants.TokenKey);
                if (callback) {
                    callback(false);
                }
            }
        } catch (error) {
            var bob = error;
        }
    }

    public static Invalidate(callback: ServiceCallback<Boolean>) {
        if (TokenService.IsAuthenticated()) {
            //TODO: Invalidate the token on the endpoint
            StorageService.Clear(Constants.TokenKey);
        }
        if (callback) {
            callback(true);
        }
    }
}