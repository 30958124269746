import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ParkingComponent from '../components/ParkingComponent';
import { Container } from 'react-bootstrap';

interface IProps extends RouteComponentProps { }
interface IState {
 }

class Parking extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div>
                <ParkingComponent />
            </div>
        );
    }
}

export default withRouter(Parking);
