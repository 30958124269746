import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { basename } from 'path';

const baseUrlString = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

if(baseUrlString){
ReactDOM.render(
  <BrowserRouter basename={baseUrlString}>
    <App />
  </BrowserRouter>,
  rootElement);
}
registerServiceWorker();

