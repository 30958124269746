import {
    ServiceCallback,
    PatchOperation,
    PagedData,
} from "../models/Models"
import { StorageService } from "./StorageService";
import { Constants } from "./Constants";
import TokenService from './TokenService';

interface IDictionary {
    [index: string]: any;
}

export default class BaseService<T> {

    endpoint: string;
    args: IDictionary;

    constructor(endpoint: string) {
        this.endpoint = endpoint;
        this.args = {};
    }

    Delete(id: string, callback: any) {
        HTTPServiceCalls.Delete(this.endpoint, id, callback);
    }

    UpdateSingle(id: string, property: string, value: string, callback: any) {
        var properties = new Array();
        properties.push({ property: property, value: value });
        this.Update(id, properties, callback);
    }

    Update(id: string, properties: Array<PatchProperty>, callback: any) {
        var patchOperations = new Array<PatchOperation>();

        properties.map(function (property, index) {
            patchOperations.push({
                op: "replace",
                path: property.property,
                value: property.value
            });
        })

        HTTPServiceCalls.Patch(this.endpoint, id, patchOperations, callback);
    }

    Create<T>(model: T, callback: any) {
        HTTPServiceCalls.Post(this.endpoint, model, callback);
    }

    CreateWithEndpoint<T>(endpoint: string, model: T, callback: any) {
        HTTPServiceCalls.Post(endpoint, model, callback);
    }

    Action<T>(action: string, callback: any) {
        HTTPServiceCalls.Post(this.endpoint + "/" + action, {}, callback);
    }

    ActionWithData<T>(action: string, model: any, callback: any) {
        HTTPServiceCalls.Post(this.endpoint + "/" + action, model, callback);
    }

    GetDetail<T>(id: string, callback: any) {
        HTTPServiceCalls.GetDetail(this.endpoint, id, callback);
    }

    Get<T>(skip: number, take: number, callback: any) {
        HTTPServiceCalls.Get(this.endpoint, skip, take, this.args, callback);
    }

    GetWithEndpoint<T>(endpointOverride: string, skip: number, take: number, callback: any) {
        HTTPServiceCalls.Get(endpointOverride, skip, take, this.args, callback);
    }

    public GetAll<T>(callback: any) {
        HTTPServiceCalls.GetAll(this.endpoint, this.args, callback);
    }

    public With(arg: string, value: Object) : BaseService<T>{
         this.args[arg] = value;
         return this;
     }

    Sorted(sort: string, direction: string): BaseService<T> {
         this.With("sort", sort);
         this.With("direction", direction);
         return this;
     }

    WithTimeFrame(start: Date, end: Date): BaseService<T> {
         this.With("start", start);
         this.With("end", end);
         return this;
     }

    WithRegion(region: string): BaseService<T> {
         this.With("regionId", region);
         return this;
     }

}

interface PatchProperty {
    property: string;
    value: string;
}

class HTTPServiceCalls {

    static Get<T>(endpoint: string, skip: number, take: number, args: IDictionary, callback: any) {
        var token = TokenService.Get();

        var query = "";
        for (var property in args) {
            if (args.hasOwnProperty(property)) {
                var val = args[property];
                if (val != null) {
                    if (val instanceof Date)
                        query = query + "&" + property + "=" + (val as Date).toISOString();
                    else
                        query = query + "&" + property + "=" + val;
                }
            }
        }

        var url = Constants.VlifeDomainUrl + "/api/" + endpoint + "?skip=" + skip + "&take=" + take + query

        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'X-Admin-Suppressed':'False'
                }
            })
            .then((response: any) => {
                if (response) {

                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(undefined);
                    }
                }
            })
            .catch(xhr => {
                if (xhr.status == 401) {
                    TokenService.RefreshToken((result: boolean) => {
                        if (result) {
                            window.location.reload();
                        } else {
                            StorageService.Clear(Constants.TokenKey);
                            window.location.href = "/signin";
                        }
                    });
                } else if (callback) {
                    callback(undefined, xhr.response);
                }
            });
    }

    static GetAll<T>(endpoint: string, args: IDictionary, callback: any) {
        var token = TokenService.Get();

        var query = "";
        for (var property in args) {
            if (args.hasOwnProperty(property)) {
                var val = args[property];
                if (val != null) {
                    if (val instanceof Date)
                        query = query + "&" + property + "=" + (<Date>val).toISOString();
                    else
                        query = query + "&" + property + "=" + val;
                }
            }
        }

        HTTPServiceCalls.Get<T>(endpoint, 0, 0, args, function (result: PagedData<T>) {
            var url = Constants.VlifeDomainUrl + "/api/" + endpoint + "?skip=0&take=" + result.total + query;
            fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'X-Admin-Suppressed':'False'
                    }
                })
                .then((response: any) => {
                    if (response) {

                        if (callback) {
                            callback(response);
                        }
                    } else {
                        if (callback) {
                            callback(undefined);
                        }
                    }
                })
                .catch(xhr => {
                    if (xhr.status == 401) {
                        TokenService.RefreshToken((result: boolean) => {
                            if (result) {
                                window.location.reload();
                            } else {
                                StorageService.Clear(Constants.TokenKey);
                                window.location.href = "/signin";
                            }
                        });
                    } else if (callback) {
                        callback(undefined, xhr.response);
                    }
                });
        });
    }

    static GetDetail<T>(endpoint: string, id: string, callback: any) {

        var token = TokenService.Get();

        var url = Constants.VlifeDomainUrl + "/api/" + endpoint + "/" + id + "?permisionHinting=true"
        fetch(url,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .then((response: any) => {
                if (response) {

                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(undefined);
                    }
                }
            })
            .catch(xhr => {
                if (xhr.status == 401) {
                    TokenService.RefreshToken((result: boolean) => {
                        if (result) {
                            window.location.reload();
                        } else {
                            StorageService.Clear(Constants.TokenKey);
                            window.location.href = "/signin";
                        }
                    });
                } else if (callback) {
                    callback(undefined, xhr.response);
                }
            });

    }

    static Delete(endpoint: string, id: string, callback: any) {
        var token = TokenService.Get();
        var url = Constants.VlifeDomainUrl + "/api/" + endpoint + "/" + id;
        fetch(url,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            .then((response: any) => {
                if (response) {

                    if (callback) {
                        callback(response);
                    }
                } else {
                    if (callback) {
                        callback(undefined);
                    }
                }
            })
            .catch(xhr => {
                if (xhr.status == 401) {
                    TokenService.RefreshToken((result: boolean) => {
                        if (result) {
                            window.location.reload();
                        } else {
                            StorageService.Clear(Constants.TokenKey);
                            window.location.href = "/signin";
                        }
                    });
                } else if (callback) {
                    callback(undefined, xhr.response);
                }
            });
    }

    static Patch(endpoint: string, id: string, data: Array<PatchOperation>, callback: any) {
        var token = TokenService.Get();
        let url = Constants.VlifeDomainUrl + "/api/" + endpoint + "/" + id;

        var json = JSON.stringify(data);

        fetch(url,
            {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json-patch+json'
                },

                body: json
            })
            .then((response: any) => {
                if (callback) {
                    callback(response);
                }
            })
            .catch(error => {
                if (callback) {
                    callback(error);
                }
            });

    }

    static Post<T>(endpoint: string, data: T, callback: any) {
        var token = TokenService.Get();
        let url = Constants.VlifeDomainUrl + "/api/" + endpoint;

        var json = JSON.stringify(data);

        fetch(url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },

                body: json
            })
            .then((response: any) => {
                if (callback) {
                    callback(response);
                }
            })
            .catch(error => {
                if (callback) {
                    callback(error);
                }
            });
    }
}