import React, { Component } from "react";
import { Spinner, Table, Modal, Button, Container, Row, Col } from "react-bootstrap";
import CarsService from "../../services/CarsService";
import { CarModel } from "../../models/CarModel";
import { CarParkReservationModel } from "../../models/CarParkReservationModel";
import CarListComponent from "./CarListComponent";
import ParkingReservationService from "../../services/ParkingReservationService";
import { Constants } from "../../services/Constants";
import { StorageService } from "../../services/StorageService";

interface IProps {
    setShowPopup: (value: boolean) => void;
    showPopup: boolean;
    reservation: CarParkReservationModel;
}

interface IState {

    errorOccured: boolean;
    errorMessage: string;

    loading: boolean;
    cars: Array<CarModel>;

    carChangedSuccessfully: boolean;
    selectedCar: CarModel;
    getCars: boolean;
}

class ChangeCarDialog extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,

            cars: new Array<CarModel>(),
            loading: true,
            carChangedSuccessfully: true,
            selectedCar: new CarModel(),
            getCars: false
        };

        this.setState = this.setState.bind(this);
        this.handleGetCarsResponse = this.handleGetCarsResponse.bind(this);
        this.handleCarSelected = this.handleCarSelected.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.clearState = this.clearState.bind(this);

        this.handleChangeCarResponse = this.handleChangeCarResponse.bind(this);
        this.setGetCars = this.setGetCars.bind(this);
    }

    async handleGetCarsResponse(success: boolean, message: string, carList: Array<CarModel>) {
        this.setState({ loading: false });

        if (success) {
            this.setState({ cars: carList });
        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: message });
        }
    }

    componentDidMount() {
        this.setState({ carChangedSuccessfully: false });
        this.setState({ errorOccured: false });
        this.setState({ errorMessage: "" });
        const service = CarsService.New();
        this.setState({ loading: true });
        service.GetUserCars(this.handleGetCarsResponse);
    }

    handleChangeCarResponse(response: any) {
        this.clearState();
        this.setState({ loading: false });
        if (response) {
            if (response.status === 200) {
                this.setState({ carChangedSuccessfully: true });
                return;
            } else if (response.status === 401) {
                StorageService.Clear(Constants.UserKey);
                StorageService.Clear(Constants.SitesKey);
                StorageService.Clear(Constants.TokenKey);
                StorageService.Clear(Constants.RefreshTokenKey);

                window.location.href = "/signin";
            }
        }

        this.setState({ errorOccured: true });
        this.setState({ errorMessage: "Error: There was a problem changing your car for this booking." });
    }

    handleCarSelected(car: CarModel) {
        if (car) {
            this.setState({ loading: true });
            let service = ParkingReservationService.New();
            service.UpdateSingle(this.props.reservation.id, "carId", car.id, this.handleChangeCarResponse)
        }
    }

    clearState() {
        this.setState({ carChangedSuccessfully: false });
        this.setState({ errorOccured: false });
        this.setState({ errorMessage: "" });
        this.setState({ loading: false });
    }

    handleClose() {
        this.clearState();
        this.props.setShowPopup(false);
    }

    setGetCars(value: boolean) {
        this.setState({ getCars: value });
    }

    createModaloptionsPopup() {
        const modalPopup =
            <div style={{ maxWidth: '100%' }}>
                <Modal
                    title=""
                    show={this.props.showPopup}
                    onHide={this.handleClose}>

                    <Modal.Header closeButton>
                        <Modal.Title>Select Car</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row style={{ height: '400px' }}>
                                <Col>
                                    {this.state.loading ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                                <span className="sr-only">...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                            this.state.errorOccured ? (
                                                <p style={{ color: 'red' }}>
                                                    {this.state.errorMessage}
                                                </p>
                                            ) : (
                                                    this.state.carChangedSuccessfully ? (
                                                        <p style={{ color: 'green' }}>
                                                            Your car has been updated for this parking reservation.
                                                        </p>
                                                    ) : (
                                                            <CarListComponent
                                                                handleCarSelected={this.handleCarSelected}
                                                                getCars={this.state.getCars}
                                                                setGetCars={this.setGetCars}
                                                                displayDeleteIcon={false}
                                                            />)
                                                )
                                        )}
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                            <Button onClick={this.handleClose}>OK</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        return modalPopup
    }

    render() {
        const popup = this.createModaloptionsPopup()
        return (
            <>
                {popup}
            </>);
    }
}

export default ChangeCarDialog;