import React, { Component } from 'react';
import TeamMemberModel from '../models/TeamMemberModel';
import TeamMembersService from '../services/TeamMembersService';
import { Table, Spinner, Card } from 'react-bootstrap';
import { StorageService } from '../services/StorageService';
import { Constants } from '../services/Constants';

interface IProps {

}

interface IState {
    teamMembers: TeamMemberModel[];
    total: number;
    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;
}

export default class TeamMemberComponent extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            teamMembers: new Array<TeamMemberModel>(),
            total: 0,
            loading: false,
            errorMessage: "",
            errorOccured: false
        };

        this.handleResponseData = this.handleResponseData.bind(this);
    }

    componentDidMount() {

        var user = StorageService.Get(Constants.UserKey);

        if (user) {
            var userModel = JSON.parse(user);

            if (userModel) {

                if (userModel.id) {
                    this.setState({ loading: true });

                    var service = TeamMembersService.New();

                    service.GetWithEndpoint("users/" + userModel.id + "/team", 0, 200, this.handleResponseData);
                }
            }
        } else {
            StorageService.Clear(Constants.UserKey);
            StorageService.Clear(Constants.SitesKey);
            StorageService.Clear(Constants.TokenKey);
            StorageService.Clear(Constants.RefreshTokenKey);

            window.location.href = "/signin";
        }
    }

    async handleResponseData(result: any) {
        this.setState({ loading: false });

        try {

            if (result) {

                if (result.status === 200) {
                    var responseData = await result.json();

                    if (responseData) {
                        console.log(result);
                        this.setState({ total: responseData.total })

                        this.setState({ teamMembers: responseData.data })
                    } else {
                        this.setState({ errorOccured: true });
                        this.setState({ errorMessage: "Error: Unabe to retrieve team members." });
                    }
                } else if (result.status === 401) {
                    StorageService.Clear(Constants.UserKey);
                    StorageService.Clear(Constants.SitesKey);
                    StorageService.Clear(Constants.TokenKey);
                    StorageService.Clear(Constants.RefreshTokenKey);

                    window.location.href = "/signin";

                } else {
                    this.setState({ errorOccured: true });
                    this.setState({ errorMessage: "Error: Unabe to retrieve team members." });
                }
            } else {
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Error: Unabe to retrieve team members." });
            }

        } catch (Error) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: Unabe to retrieve team members." });
        }
    }

    render() {
        return (
            <Card style={{ marginTop: "50px" }}>
                <Card.Header>
                    <h2>My team</h2>
                </Card.Header>
                <Card.Body style={{padding: 0}}>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Last Name</th>
                            <th>Completed Check in</th>
                            <th>On Site</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.loading ? (
                            <Spinner style={{ float: 'left', marginTop: '10px' }} animation="border" role="status" size="sm">
                                <span className="sr-only">Publish ...</span>
                            </Spinner>
                        ) : (!this.state.errorOccured ?
                            (this.state.teamMembers ? (
                                this.state.teamMembers.map((teamMember: TeamMemberModel, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                {teamMember.name}
                                            </td>
                                            <td>
                                                {teamMember.lastName}
                                            </td>
                                            <td>
                                                {teamMember.completedMyCheck && "Yes"}
                                                {!teamMember.completedMyCheck && "No"}
                                            </td>
                                            <td>
                                                {!teamMember.completedMyCheck && <i>n/a</i>}
                                                {teamMember.completedMyCheck && teamMember.isOnsite && "Onsite"}
                                                {teamMember.completedMyCheck && !teamMember.isOnsite && "Offsite"}
                                            </td>
                                        </tr>
                                    )
                                })) : (null)) : (
                                <tr>
                                    <td colSpan={4} style={{color: 'red'}}>
                                        {this.state.errorMessage}
                                    </td>
                                </tr>))}
                    </tbody>
                    </Table>
                    </Card.Body>
            </Card>
        );
    }
}