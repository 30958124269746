import { SiteModel } from '../models/SitesModel';
import { PaginatedModel } from '../models/PaginatedModel';
import { UserModel } from '../models/UserModel';
import BaseService from './BaseService';
import { Constants } from './Constants';
import { StorageService } from './StorageService';

export default class SiteService extends BaseService<PaginatedModel<SiteModel>> {

    constructor() {
        super("sites");
    }

    public static New(): SiteService {
        return new SiteService();
    }

    public async GetUserSites(siteTypeIds: Array<string>, handleResponse: (success: boolean, message: string, sites: Array<SiteModel>) => void) {

        var userData = StorageService.Get(Constants.UserKey);
        if (userData) {

            var userModel = JSON.parse(userData) as UserModel;

            //TODO - dynamic assignment of list of site types - remove hard coding
            var defaultSiteIds = new Array<string>();
            if(siteTypeIds.length > 0) {
                defaultSiteIds = siteTypeIds;
            } else {
                defaultSiteIds.push("0C1C9740-2FB0-4D9D-A3B8-D0CA51D2250D") 
            }
          
            if (userModel) {
                this.With("siteTypeId", defaultSiteIds[0])
                    .Get(0, 200, async function (sitesResponse: any) {
                        if (sitesResponse) {
                            if (sitesResponse.status === 200) {
                                var sitesPage = await sitesResponse.json() as PaginatedModel<SiteModel>;
                                console.log(sitesPage);
                                if (sitesPage) {
                                    var sitesJson = JSON.stringify(sitesPage.data);
                                    StorageService.Store(Constants.SitesKey, sitesJson);
                                    handleResponse(true, "", sitesPage.data);
                                }
                            } else if (sitesResponse.status === 401) {
                                window.location.href = "/signin";
                            } else {
                                // handle unkown error
                                alert("There was a problem getting sites for user.");
                            }
                        } else {

                            // TODO - handle error - user must belong to a site
                            alert("There was a problem getting sites for user.");

                        }
                    });
            }
        }
    }
}

