import React, { Component } from 'react';
import TeamMemberComponent from '../components/TeamMemberComponent';

export class MyTeam extends Component {

    render() {
        return (
            <div>
                <TeamMemberComponent/>
            </div>
        );
    }
}
