import React, { Component } from 'react';
import DailCheckInComponent from '../components/DailyCheckInComponent';
import { RouteComponentProps } from 'react-router-dom';

interface IProps {
}

interface IState {}

export class DailyCheckin extends Component<IProps, IState, {}> {

    render() {
        return (
            <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <h3 id="tabelLabel">Daily Checkin</h3>
                <DailCheckInComponent/>
            </div>
        );
    }
}
