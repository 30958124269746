import React, { Component } from 'react';
import { Button, FormControl, InputGroup, Spinner, Row, Col } from 'react-bootstrap';
import { PostReallocationModel } from '../../models/Parking/PostReallocationModel';
import ParkingReservationService from "../../services/ParkingReservationService";
import { CarParkReservationModel } from '../../models/CarParkReservationModel';

interface IProps {
    reservation: CarParkReservationModel;
    setDialogOpen: (value: boolean) => void;
}

interface IState {

    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;

    textAreaText: string;
    realocationSuccess: boolean;
    newReservation: CarParkReservationModel;
}

class ParkTakenComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: "",
            errorOccured: false,
            textAreaText: "",
            realocationSuccess: false,
            newReservation: new CarParkReservationModel()
        };

        this.clearErrorMessage = this.clearErrorMessage.bind(this);
        this.handleTextTextAreaChange = this.handleTextTextAreaChange.bind(this);
        this.handleFindNewParkClick = this.handleFindNewParkClick.bind(this);
        this.handleFindParkResponse = this.handleFindParkResponse.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
        this.clearErrorMessage = this.clearErrorMessage.bind(this);
        this.handleOKClick = this.handleOKClick.bind(this);
    }

    handleTextTextAreaChange(e: any) {
        var text = e.target.value;
        this.setState({ textAreaText: text });
    }

    setErrorMessage(errorMessage: string) {
        this.setState({ errorOccured: true });
        this.setState({ errorMessage: errorMessage });
    }

    clearErrorMessage() {
        this.setState({ errorOccured: false });
        this.setState({ errorMessage: "" });
    }

    async handleFindParkResponse(response: any) {
        this.clearErrorMessage();
        this.setState({ loading: false })
        if (response) {
            if (response.status === 200) {

                var responseData = await response.json() as CarParkReservationModel;
                if (responseData) {
                    this.setState({ newReservation: responseData });
                    this.setState({ realocationSuccess: true });
                }
            } else if (response.status === 400) {
                // Display validation errors
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Sorry - we are unable to find an available park at this time" });
                var validationData = await response.json();
                if (validationData.code) {
                    if (validationData.code === "ValidationException") {
                        if (validationData.errors) {
                            if (validationData.errors.length > 0) {
                                var error = validationData.errors.find((e: any) => e.key === "DateBooked");
                                if (error) {
                                    this.setState({ errorMessage: error.error });
                                }
                            }
                        }
                    }
                }

            } else {
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: "Error: there was a problem reallocating your park." });
            }

        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: there was a problem reallocating your park." });
        }
    }

    handleFindNewParkClick() {

        this.clearErrorMessage();
        this.setState({ loading: true });
        var service = ParkingReservationService.New();

        var model = new PostReallocationModel();

        if (this.state.textAreaText.length == 0) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: Please enter the number plate of the vehicle in your park." });
            this.setState({ loading: false });
            return;
        }

        if (this.state.textAreaText.length > 10) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: Number plate cannot have more than 10 characters." });
            this.setState({ loading: false });
            return;
        }

        if (this.props.reservation.car.numberPlate == this.state.textAreaText) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: You've entered the number plate of your own car." });
            this.setState({ loading: false });
            return;
        }

        model.registration = this.state.textAreaText;

        var reservationId = this.props.reservation.id;
        var url = "parking-reservations/" + reservationId + "/reallocate-park";

        service.CreateWithEndpoint(url, model, this.handleFindParkResponse)
    }

    handleOKClick() {
        this.props.setDialogOpen(false);
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <Row>
                        <Col>
                            <div style={{ textAlign: 'center', height: '70px' }}>
                                <Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                    <span className="sr-only">...</span>
                                </Spinner>
                            </div>
                        </Col>
                    </Row>

                ) : (this.state.realocationSuccess ? (
                    <>
                        <Row  style={{ height: '70px' }}>
                            <Col>
                            </Col>
                            <Col xs={8} style={{ textAlign: "center", color: 'green' }}>
                                <h4>Your new car park is {this.state.newReservation.park.name}</h4>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                        <Row   style={{  height: '70px' }}>
                            <Col>
                            </Col>
                            <Col xs={8} style={{ textAlign: "center", color: 'green' }}>
                                <Button onClick={this.handleOKClick}>OK</Button>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </>
                ) : (
                        <>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center" }}>
                                    <h4>Park Taken</h4>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center", marginBottom: '30px' }}>
                                    No problem - we'll look for a new park.
                                        </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center", marginBottom: '30px' }}>
                                    Enter the number plate of the vehicle in your park.
                                            </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={10} style={{ textAlign: "center", marginBottom: '30px' }}>
                                    <InputGroup>
                                        <FormControl
                                            as="textarea"
                                            aria-label=""
                                            value={this.state.textAreaText}
                                            onChange={this.handleTextTextAreaChange}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center" }}>
                                    <Button onClick={this.handleFindNewParkClick} style={{ cursor: 'pointer' }}>Find new park</Button>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                        </>
                    ))}
                {this.state.errorOccured ? (
                    <>
                        <Row>
                            <Col>
                                <p style={{ textAlign: "center", color: 'red' }}>
                                    {this.state.errorMessage}
                                </p>
                            </Col>
                        </Row>
                    </>
                ) : (null)}
            </>
        );
    }
}

export default ParkTakenComponent;