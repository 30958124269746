import { CarParkReservationModel } from '../models/CarParkReservationModel';
import BaseService from './BaseService';

export default class ParkingReservationService extends BaseService<CarParkReservationModel> {

    constructor() {
        super("parking-reservations");
    }

    public static New(): ParkingReservationService  {
        return new ParkingReservationService();
    }
}

