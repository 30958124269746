import React, { Component } from "react";
import { Spinner,  Modal, Button, Container, Row, Col } from "react-bootstrap";
import { CarParkReservationModel } from "../../models/CarParkReservationModel";
import ParkingReservationService from "../../services/ParkingReservationService";
import { Person, ExclamationTriangleFill, TruckFlatbed } from 'react-bootstrap-icons';
import YesNoDialogComponent from '../YesNoDialogComponent';
import { CarModel } from "../../models/CarModel";
import ChangeCarDialog from './ChangeCarDialog';
import SomeonesInMyParkDialog from './SomeonesInMyParkDialog';

interface IProps {
    showPopup: boolean;
    reservation: CarParkReservationModel;
    setShowPopup: (value: boolean) => void;
}

interface IState {
    errorOccured: boolean;
    errorMessage: string;
    submitSuccess: boolean;
    cancelLoading: boolean;
    cancelSuccess: boolean;
    showYesNoPop: boolean;
    showChangeCarDialog: boolean;
    showSomeonesInMyParkDialog: boolean;
}

class BookingOptionsComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            errorMessage: "",
            errorOccured: false,
            submitSuccess: false,
            cancelLoading: false,
            cancelSuccess: false,
            showYesNoPop: false,
            showChangeCarDialog: false,
            showSomeonesInMyParkDialog: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.showYesNoPopupHandler = this.showYesNoPopupHandler.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
        this.changeCar = this.changeCar.bind(this);
        this.someonesInMyPark = this.someonesInMyPark.bind(this);
        this.setState = this.setState.bind(this);
        this.setYesNoPopupAnswer = this.setYesNoPopupAnswer.bind(this);
        this.handleCancelResponseData = this.handleCancelResponseData.bind(this);
        this.cancelBookingClick = this.cancelBookingClick.bind(this);
        this.showChangeCarDialog = this.showChangeCarDialog.bind(this);
        this.showSomeonseInMyParkDialog = this.showSomeonseInMyParkDialog.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
    }

    showYesNoPopupHandler(value: boolean) {
        this.setState({ showYesNoPop: value });
    }

    setYesNoPopupAnswer(value: string) {
        this.showYesNoPopupHandler(false);

        if (value === "yes") {
            this.cancelBooking();
        }
    }

    cancelBookingClick() {
        this.showYesNoPopupHandler(true);
    }

    cancelBooking() {

        if (this.props.reservation) {
            this.setState({ cancelLoading: true });
            ParkingReservationService.New()
                .Delete(this.props.reservation.id, this.handleCancelResponseData);
        }
    }

    async handleCancelResponseData(result: any) {

        this.setState({ cancelLoading: false });

        try {
            if (result) {
                if (result.status === 200) {
                    this.setState({ cancelSuccess: true });
                } else {
                    this.setState({ errorOccured: true });
                    this.setState({ errorMessage: "Error: There was a problem cancelling this reservation" });
                }
            }
        } catch (error) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: "Error: There was a problem cancelling this reservation" });
        }
    }

    changeCar() {
        this.setState({ showChangeCarDialog: true });
    }

    someonesInMyPark() {
        this.setState({ showSomeonesInMyParkDialog: true });
    }

    showChangeCarDialog(value: boolean) {
        this.setState({ showChangeCarDialog: value });
    }

    showSomeonseInMyParkDialog(value: boolean) {
        this.setState({ showSomeonesInMyParkDialog: value });
    }

    render() {

        return (
            <>
                <YesNoDialogComponent
                    showPopup={this.state.showYesNoPop}
                    setShowPopup={this.showYesNoPopupHandler}
                    handleAnswer={this.setYesNoPopupAnswer}
                    yesNoQuestion={"Are you sure you want to cancel this reservation?"}
                />

                <ChangeCarDialog
                    setShowPopup={this.showChangeCarDialog}
                    showPopup={this.state.showChangeCarDialog}
                    reservation={this.props.reservation}
                />

                <SomeonesInMyParkDialog
                    showPopup={this.state.showSomeonesInMyParkDialog}
                    setShowPopup={this.showSomeonseInMyParkDialog}
                    reservation={this.props.reservation}
                />

                <Modal title="" show={this.props.showPopup} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Options for booking {this.props.reservation.dateBooked}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.reservation ? (
                            <Container>
                                <Row>
                                    <Col>
                                       {this.props.reservation.park ? (this.props.reservation.park.name) : (null)}
                                    </Col>
                                    <Col>
                                        {this.props.reservation.site ? (
                                            <p>
                                                {this.props.reservation.site.name}
                                        </p>) : (null)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={2}>
                                        <ExclamationTriangleFill size={30} />
                                    </Col>
                                    <Col style={{ height: '80px' }}>
                                        <Button style={{width: '180px'}} onClick={this.cancelBookingClick}> Cancel </Button>
                                    </Col>
                                    <Col>
                                        {this.state.cancelLoading ? (
                                            <Spinner style={{ float: 'left', marginTop: '10px' }} animation="border" role="status" size="sm">
                                                <span className="sr-only">...</span>
                                            </Spinner>
                                        ) : (
                                                this.state.cancelSuccess ? (<p style={{ color: 'green' }}>Reservation cancelled</p>) :
                                                    (null))
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                   
                                    <Col xs={2}>
                                        <TruckFlatbed size={30} />
                                    </Col>
                                    <Col xs={4} style={{ height: '80px' }}>
                                        <Button style={{width: '180px'}} onClick={this.changeCar}>Change Car</Button>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                {this.props.reservation.site.allocatedParking ? (
                                    <Row>
                                        <Col xs={2}>
                                            <Person size={30} />
                                        </Col>
                                        <Col style={{ height: '80px' }}>
                                            <Button style={{width: '180px'}} onClick={this.someonesInMyPark}>Someone's in my park</Button>
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </Container>
                        ) : (null)}
                    </Modal.Body>
                    <Modal.Footer>
                    <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        Booking Options
                     </div>
                     <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                        <Button onClick={this.handleClose}>OK</Button>
                     </div>
                    </Modal.Footer>
                </Modal>
            </>);
    }
}

export default BookingOptionsComponent;