import React, { Component } from "react";
import { Spinner,  Button, Container, Row, Col } from "react-bootstrap";
import { PostParkingReservationModel } from "../../models/Parking/PostParkingReservationModel";
import ParkingReservationService from "../../services/ParkingReservationService";
import { StorageService } from "../../services/StorageService";
import { Constants } from "../../services/Constants";

interface IProps {
    reservationRequest: PostParkingReservationModel;
    handleClose: () => void;
    setSaveBookingStatus: (value: boolean) => void;
}

interface IState {

    errorOccured: boolean;
    errorMessage: string;
    bookingSuccess: boolean;
    loading: boolean;
}

class PreviewBookingComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,
            bookingSuccess: false,
            loading: false
        };

        this.handleReservationResponse = this.handleReservationResponse.bind(this);
        this.handleBookNowClick = this.handleBookNowClick.bind(this);
        this.handleReservationResponse = this.handleReservationResponse.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    async handleReservationResponse(response: any) {
        this.setState({ loading: false });

        if (response) {
            if (response.status === 200) {
                this.setState({ bookingSuccess: true });
            } else if (response.status === 400) {
                var responseData = await response.json();

                var errorMessage = "Error: there was a problem creating your booking - status: " + response.status
                if (responseData.errors) {
                    if (responseData.errors) {
                        if (responseData.errors.length > 0) {
                            if (responseData.errors[0].error.indexOf("already booked on this day") >= 0) {
                                errorMessage = "You already have a park booked for this date."
                            } else {
                                errorMessage = responseData.errors[0].error;
                            }
                        }
                    }
                }

                this.setState({ errorOccured: true });
                this.setState({ errorMessage: errorMessage });

            } else if (response.status === 401) {
                StorageService.Clear(Constants.UserKey);
                StorageService.Clear(Constants.SitesKey);
                StorageService.Clear(Constants.TokenKey);
                StorageService.Clear(Constants.RefreshTokenKey);

                window.location.href = "/signin";
            } else {
                var errorMessage = "Error: there was a problem creating your booking";
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: errorMessage });
            }
        }
        else {
            var errorMessage = "Error: there was a problem creating your booking";
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: errorMessage });
        }
    }

    handleBookNowClick(e: any) {
        this.setState({ loading: true });
        var reservationRequest = this.props.reservationRequest;
        ParkingReservationService.New().Create(reservationRequest, this.handleReservationResponse);
    }

    handleCloseClick(e: any) {
        this.props.handleClose()
    }

    render() {

        return (
            <>
                <Container>
                    {this.state.loading ? (
                        <Row>
                            <Col>
                            </Col>
                            <Col xs={2}>
                                < Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                    <span className="sr-only">...</span>
                                </Spinner>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    ) : (
                            <>
                                <Row><Col>
                                    </Col>
                                    <Col xs={6} style={{ height: '30px' }}>
                                        {this.state.bookingSuccess ? (
                                            <p style={{ marginTop: '10px', color: 'green' }}>Your parking was booked successfully.</p>)
                                            : (this.state.errorOccured ? (
                                                <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                                            ) : (null))}
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                    <Col xs={4} style={{ height: '30px' }}>
                                        {this.state.bookingSuccess || this.state.errorOccured ? (
                                            <Button
                                                style={{ marginTop: '80px', cursor: 'pointer', width: '124px' }}
                                                onClick={this.handleCloseClick}>
                                                OK
                                            </Button>
                                        ) : (null)}
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>

                            </>
                        )}

                    {(!this.state.loading && !this.state.bookingSuccess && !this.state.errorOccured) ? (
                        <>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center" }}>
                                    <h4>Confirm Parking</h4>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center" }}>
                                    You're booking parking for
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={6} style={{ textAlign: "center" }}>
                                    <h4>{this.props.reservationRequest.displayDateBooked}</h4>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8} style={{ textAlign: "center" }}>
                                    {this.props.reservationRequest.sitename}
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={2} style={{ textAlign: "center" }}>
                                    ${this.props.reservationRequest.rate}
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={8}>
                                    <p style={{ textAlign: "center" }}>
                                        Parking is non refundable
                            </p>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs={4}>
                                    <Button style={{ cursor: 'pointer', width: '100px' }} onClick={this.handleBookNowClick}>Book Now</Button>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                        </>
                    ) : (null)}
                </Container>
            </>);
    }
}

export default PreviewBookingComponent;