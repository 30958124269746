import BaseService from './BaseService';
import { StorageService } from './StorageService';
import { Constants } from './Constants';
import { CarModel } from '../models/CarModel';
import { UserModel } from '../models/UserModel';
import { PaginatedModel } from '../models/PaginatedModel';
import { CreditCardModel } from '../models/CreditCardModel';
import CreditCardListComponent from '../components/Parking/CreditCardListComponent';

export default class CreditCardService extends BaseService<CarModel> {

    constructor() {
        super("credit-cards");
    }

    public async GetUserCreditCards(callBack:(success: boolean, message: string, cars: Array<CreditCardModel>) => void) {

        var userJson = StorageService.Get(Constants.UserKey);
        if (userJson) {
            var user = JSON.parse(userJson) as UserModel;
            if (user) {
                this.With("userId", user.id)
                    .Get(
                        0,
                        200,
                        async function (response: any) {
                            if (response) {
                                var paginatedCardsList = await response.json() as PaginatedModel<CreditCardModel>;
                                console.log(paginatedCardsList);
                                callBack(true, "", paginatedCardsList.data);
                            } else {

                                callBack(false, "Error: problem getting your credit cards", []);
                            }
                        });
            } else {
                // TODO - redirect to sign in page?
            }
        }
    }

    public static New(): CreditCardService  {
        return new CreditCardService();
    }
}

