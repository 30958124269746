import React, { Component } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";


interface IProps {
    showPopup: boolean;
    yesNoQuestion: string;
    setShowPopup: (value: boolean) => void;
    handleAnswer: (answer: string) => void;
}

interface IState {
  
}

class YesNoDialogComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
        
        };

        this.createModaloptionsPopup = this.createModaloptionsPopup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setState = this.setState.bind(this);
        this.handlebuttonClick = this.handlebuttonClick.bind(this);
        this.handlebuttonClick = this.handlebuttonClick.bind(this);
    }

    handleClose() {
        this.props.setShowPopup(false);
    }

    handlebuttonClick(e: any) {
        let value = e.target.value;

        if (value) {
            this.props.handleAnswer(value);
        } else {
            // TODO - handle error
        }
    }
 
    createModaloptionsPopup() {
        const modalPopup =
            <Modal 
                title="" 
                show={this.props.showPopup} 
                onHide={this.handleClose}
                size={'sm'}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.yesNoQuestion}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Button onClick={this.handlebuttonClick} value={'yes'}>Yes</Button>
                            </Col>
                            <Col style={{ height: '50px' }}>
                                <Button onClick={this.handlebuttonClick} value={'no'}>No</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ height: '30px'}}>
                    </div>
                </Modal.Footer>
            </Modal>

        return modalPopup
    }

    render() {

        var popUp = this.createModaloptionsPopup();

        return (popUp);
    }
}

export default YesNoDialogComponent;