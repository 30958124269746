import React, { Component } from "react";
import { Spinner, Table, Modal, Button, Container, Row, Col } from "react-bootstrap";
import CarsService from "../../services/CarsService";
import { CarModel } from "../../models/CarModel";

interface IProps {
    handleCarSelected: (car: CarModel) => void;
    getCars: boolean;
    setGetCars: (value: boolean) => void;
    displayDeleteIcon: boolean;
}

interface IState {

    errorOccured: boolean;
    errorMessage: string;

    loading: boolean;
    cars: Array<CarModel>;
}

class CarListComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: "",
            errorOccured: false,

            cars: new Array<CarModel>(),
            loading: true
        };

        this.setState = this.setState.bind(this);
        this.handleGetCarsResponse = this.handleGetCarsResponse.bind(this);
        this.handleCarSelected = this.handleCarSelected.bind(this);
    }

    async handleGetCarsResponse(success: boolean, message: string, carList: Array<CarModel>) {
        this.setState({ loading: false });

        if (success) {
            this.setState({ cars: carList });
        } else {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: message });
        }
    }

    componentDidMount() {
        const service = CarsService.New();

        this.setState({ loading: true });

        service.GetUserCars(this.handleGetCarsResponse);
    }

    componentDidUpdate() {
        if (this.props.getCars) {
            const service = CarsService.New();

            this.setState({ loading: true });

            service.GetUserCars(this.handleGetCarsResponse);

            this.props.setGetCars(false);
        }
    }

    handleCarSelected(e: any) {
        var carId = e.target.id;
        if (carId) {
            var car = this.state.cars.find(p => p.id === carId);
            if (car) {
                this.props.handleCarSelected(car);
            }
        }
    }

    render() {

        return (
            <>
                <Container>
                    <Row>
                        <Col style={{ height: '300px', overflowY: 'auto' }}>
                            <Table >
                                <thead>
                                    <tr>
                                        <th>Make</th>
                                        <th>Rego</th>
                                        <th style={{ textAlign: 'center' }}>
                                            {this.props.displayDeleteIcon ? (
                                                <span>Delete</span>
                                            ) : (null)}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? (
                                        <tr>
                                            <td colSpan={3}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                                        <span className="sr-only">...</span>
                                                    </Spinner>
                                                </div>
                                            </td>
                                        </tr>) :
                                        (this.state.cars ? (
                                            this.state.cars.map((car: CarModel, index) => {
                                                return (
                                                    <tr
                                                        onClick={this.handleCarSelected}
                                                        key={car.id}
                                                        id={car.id}
                                                        style={{ cursor: 'pointer' }}>

                                                        <td id={car.id}>
                                                            {car.name}
                                                        </td>
                                                        <td id={car.id}>
                                                            {car.numberPlate}
                                                        </td>
                                                        <td
                                                            onClick={this.handleCarSelected}
                                                            id={car.id} 
                                                            style={{ textAlign: 'center' }}>
                                                            {this.props.displayDeleteIcon ? (
                                                                <span
                                                                    
                                                                >
                                                                    X
                                                                </span>
                                                            ) : (null)}
                                                        </td>
                                                    </tr>
                                                )
                                            })) : (null))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Container>
            </>);
    }
}

export default CarListComponent;