import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {}
interface IState { }

export default class MyWellBeing extends Component<IProps, IState> {

    render() {
        return (
            <div>
                <h1 id="tabelLabel">My Well Being</h1>
            </div>
        );
    }
}
