import React, { Component } from "react";
import { Spinner, Table, Modal, Button, Row, Col, Card } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CarParkReservationModel } from "../models/CarParkReservationModel";
import { PaginatedModel } from '../models/PaginatedModel';
import { Constants } from "../services/Constants";
import ParkingReservationService from "../services/ParkingReservationService";
import { StorageService } from "../services/StorageService";
import BookingOptionsComponent from './Parking/BookingOptionsComponent';
import BookParkingComponent from "./Parking/BookParkingComponent";

interface IProps extends RouteComponentProps {
}

interface IState {
    total: number;
    loading: boolean;
    errorOccured: boolean;
    errorMessage: string;
    submitSuccess: boolean;
    parkingReservations: Array<CarParkReservationModel>;
    selectedParkingReservation: CarParkReservationModel;
    showPopup: boolean;

    showAddBookingPopup: boolean;
}

class ParkingComponent extends Component<IProps, IState, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            total: 0,
            loading: false,
            errorMessage: "",
            errorOccured: false,
            submitSuccess: false,
            parkingReservations: new Array<CarParkReservationModel>(),
            showPopup: false,
            selectedParkingReservation: new CarParkReservationModel(),

            showAddBookingPopup: false
        };

        this.setShowPopup = this.setShowPopup.bind(this);
        this.handleResponseData = this.handleResponseData.bind(this);
        this.handleoptionButtonClick = this.handleoptionButtonClick.bind(this);
        this.handleAddBookingClick = this.handleAddBookingClick.bind(this);
        this.setShowAddBookingPopup = this.setShowAddBookingPopup.bind(this);
        this.getLatestCarParkBookings = this.getLatestCarParkBookings.bind(this);
    }

    async handleResponseData(result: any) {
        this.setState({ loading: false });
        const errorMessage = "Error: There was a problem getting your booked car parks";
        try {
            if (result) {
                if (result.status === 200) {
                    var responseData = await result.json() as PaginatedModel<CarParkReservationModel>;
                    if (responseData) {
                        if (responseData.data) {
                            var reservations = responseData.data;
                            this.setState({ parkingReservations: reservations });
                        }
                    }
                } else if (result.status === 401) {
                    StorageService.Clear(Constants.UserKey);
                    StorageService.Clear(Constants.SitesKey);
                    StorageService.Clear(Constants.TokenKey);
                    StorageService.Clear(Constants.RefreshTokenKey);

                    window.location.href = "/signin";
                } else {
                    this.setState({ errorOccured: true });
                    this.setState({ errorMessage: errorMessage });
                }
            } else {
                this.setState({ errorOccured: true });
                this.setState({ errorMessage: errorMessage });
            }
        } catch (error) {
            this.setState({ errorOccured: true });
            this.setState({ errorMessage: errorMessage });
        }
    }

    componentDidMount() {
        this.getLatestCarParkBookings();
    }

    getLatestCarParkBookings() {
        var user = StorageService.Get(Constants.UserKey);
        if (user) {
            var userModel = JSON.parse(user);
            if (userModel) {

                var fromDate = new Date();
                fromDate.setHours(0, 0, 0);

                this.setState({ loading: true });
                ParkingReservationService.New()
                    .With("userId", userModel.id)
                    .With("start", fromDate)
                    .Get(0, 200, this.handleResponseData);
            }
        }
    }

    handleoptionButtonClick(e: any) {
        const reservationId = e.target.value;
        let selectedBooking = this.state.parkingReservations.find(p => p.id == reservationId);
        if (selectedBooking) {
            this.setShowPopup(true);
            this.setState({ selectedParkingReservation: selectedBooking });
        }
    }

    setShowPopup(value: boolean) {
        this.setState({ showPopup: value });

        if (value === false) {
            this.getLatestCarParkBookings();
        }
    }

    handleAddBookingClick() {
        this.setShowAddBookingPopup(true);
    }

    setShowAddBookingPopup(value: boolean) {
        this.setState({ showAddBookingPopup: value });
        if (value === false) {
            this.getLatestCarParkBookings();
        }
    }

    render() {
        return (

            <Card style={{ marginTop: "50px" }}>
                <Card.Header>
                    <h2>Parking
                                <Button style={{ float: 'right' }} onClick={this.handleAddBookingClick}>Add Booking</Button></h2>
                </Card.Header>
                <Card.Body style={{ padding: 0 }}>

                    <div>
                        <BookingOptionsComponent
                            showPopup={this.state.showPopup}
                            setShowPopup={this.setShowPopup}
                            reservation={this.state.selectedParkingReservation}
                        />

                        <BookParkingComponent
                            showPopup={this.state.showAddBookingPopup}
                            setShowPopup={this.setShowAddBookingPopup}
                        />

                        <Row>
                            <Col>
                                {this.state.loading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <Spinner style={{ marginTop: '10px' }} animation="border" role="status">
                                            <span className="sr-only">...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Date Booked</th>
                                                    <th>Rego</th>
                                                    <th>Space</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loading ? (
                                                    <Spinner style={{ float: 'left', marginTop: '10px' }} animation="border" role="status" size="sm">
                                                        <span className="sr-only">Publish ...</span>
                                                    </Spinner>
                                                ) : (!this.state.errorOccured ?
                                                    (this.state.parkingReservations.length > 0 ? (
                                                        this.state.parkingReservations.map((reservation: CarParkReservationModel, index) => {
                                                            return (
                                                                <tr key={reservation.id}>
                                                                    <td>
                                                                        {reservation.dateBooked ?
                                                                            (reservation.dateBooked)
                                                                            : ("Not set")}

                                                                    </td>
                                                                    <td>
                                                                        {reservation.car ? (
                                                                            reservation.car.numberPlate)
                                                                            : ("Not set")}
                                                                    </td>
                                                                    <td>
                                                                        {reservation.park ? (
                                                                            reservation.park.name)
                                                                            : ("Choose on arrival")}

                                                                    </td>
                                                                    <td>
                                                                        <Button value={reservation.id} onClick={this.handleoptionButtonClick}>Options</Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })) : (
                                                            <tr>
                                                                <td>No current or future bookings </td>
                                                            </tr>)
                                                    ) :
                                                    (<tr>
                                                        <td colSpan={4} style={{ color: 'red' }}>{this.state.errorMessage}</td>
                                                    </tr>)
                                                    )}
                                            </tbody>
                                        </Table>)
                                }
                            </Col>
                        </Row>
                    </div>

                </Card.Body>

            </Card>
        );
    }
}

export default withRouter(ParkingComponent);
