import React, { Component } from 'react';

export class BookADesk extends Component {

    render() {
        return (
            <div>
                <h1 id="tabelLabel">Book a desk</h1>
            </div>
        );
    }
}
